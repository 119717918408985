export const menuItems = [
    {
        id: 1,
        label: "Navigation",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/'
    },
    {
        id: 3,
        label: "Orders",
        icon: "ri-sort-desc",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 4,
                label: 'Draft',
                link: '/orders?status=draft'
            },
            {
                id: 5,
                label: 'PostPay',
                link: '/orders?status=postpay'
            },
            {
                id: 6,
                label: 'Active',
                link: '/orders?status=active'
            },
            {
                id: 7,
                label: 'Delivered',
                link: '/orders?status=delivered'
            },
            {
                id: 8,
                label: 'Completed',
                link: '/orders?status=completed'
            },
        ]
    },
    {
        id: 9,
        label: "Categories",
        icon: "ri-layout-masonry-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 10,
                label: 'Creat New',
                link: '/categories/create'
            },
            {
                id: 11,
                label: 'View List',
                link: '/categories'
            },

        ]
    },
    {
        id: 12,
        label: "Products",
        icon: "ri-store-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 13,
                label: 'Creat New',
                link: '/products/create'
            },
            {
                id: 22,
                label: 'View List',
                link: '/products'
            },
        ]
    },
    {
        id: 21,
        label: "Reviews",
        icon: "ri-award-line",
        link: '/reviews'
    },
    {
        id: 14,
        label: "Users",
        icon: "ri-shield-user-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 15,
                label: 'Creat New',
                link: '/users/create'
            },
            {
                id: 16,
                label: 'Admin',
                link: '/users?role=admin'
            },
            {
                id: 17,
                label: 'Workers',
                link: '/users?role=worker'
            },
            {
                id: 18,
                label: 'Buyers',
                link: '/users?role=buyer'
            },
        ]
    },
    {
        id: 23,
        label: "Payments",
        icon: "ri-money-dollar-box-line",
        link: '/payments'
    },
    {
        id: 25,
        label: "Settings",
        icon: "ri-settings-2-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 26,
                label: 'Coupons',
                link: '/settings/coupons'
            }
        ]
    },
    {
        id: 24,
        label: "Payout",
        icon: "ri-refund-2-line",
        link: '/transactions'
    },
];
