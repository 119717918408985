
<template>
<div>
  <div id="wrapper">
    <Sidebar :type="layout.leftSidebarType" :width="layout.layoutWidth" 
    :size="layout.leftSidebarSize" :menu="layout.menuPosition" :topbar="layout.topbar" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page" :class="{'content-page-fixed': layout.contentPageFixed}">
      <div class="content">
        <Topbar />
        <div class="container-fluid px-0 pt-3">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</div>
</template>

<script>

import themeConfig    from '@/themeConfig.js'
import Sidebar from '@/components/app/LeftSidebar.vue'
import Topbar from '@/components/app/Topbar.vue'
import Footer from '@/components/app/Footer.vue'

export default {
  components: {
    Sidebar,
    Topbar,
    Footer
  },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  methods: {
    toggleRightSidebar() {
        document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
        document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
        this.isMenuCondensed = !this.isMenuCondensed
        if (this.isMenuCondensed)
            document.body.setAttribute('data-sidebar-size', 'condensed');
        else
            document.body.removeAttribute('data-sidebar-size', 'condensed');

        if (window.screen.width >= 992) {
            this.$router.afterEach((routeTo, routeFrom) => {
                document.body.classList.remove("sidebar-enable");
            });
        } else {
            document.body.setAttribute('data-sidebar-size', 'default');
            document.body.classList.toggle("sidebar-enable");
            this.$router.afterEach((routeTo, routeFrom) => {
                document.body.classList.remove("sidebar-enable");
            });
        }
    },
  },
  mounted() {
    document.body.removeAttribute("data-layout-mode");
    document.body.removeAttribute("data-sidebar-showuser");
    if (this.$route.query.layout) {
      this.$store.dispatch('layout/changeLayoutType', {
          layoutType: this.$route.query.layout
      })
    }
  },
}
</script>

<style type="text/css">
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f2f3f5;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .7s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .content-page.content-page-fixed{
      position: fixed;
      right:0;
      left:0;
  }
</style>
